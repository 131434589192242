<template>
  <Dialog
    v-if="true"
    :name="name"
    width="728px"
    class-own="dialog-delete-quota"
  >
    <template #title>
      <div style="font-size: 16px; font-weight: 400">
        Подтверждение операции
      </div>
    </template>

    <div>
      <div class="dialog-delete-quota__message">
        Вы действительно хотите удалить шаблон суточной квоты?
      </div>

      <div class="df jc-sb">
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-right: 4px; cursor: pointer"
          text-style="color: #000000; font-weight: 500"
          title="Нет"
          :disabled="isLoading"
          @onClick="handleRemoveQuota(false)"
        />
        <iq-mobile-button
          button-style="padding: 12px; width: 100%; margin-left: 4px; background: #F56C6C; border-color: #F56C6C; color: white; cursor: pointer"
          text-style="color: white; font-weight: 500"
          title="Подтверждаю удаление"
          :loading="isLoading"
          :disabled="isLoading"
          @onClick="handleRemoveQuota(true)"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import {
  DELETE_SAMPLE,
  GET_IS_LOADING_SAMPLES,
} from '@/views/quota-samples/store/actions'
import { QUOTA_DELETE_SAMPLE } from '@/constants/dialogs'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/UI/dialog/Dialog'
import IqMobileButton from '@/UI/form/mobileButton/IqMobileButton'

export default {
  name: 'DialogDeleteSample',
  components: { IqMobileButton, Dialog },
  data() {
    return { name: QUOTA_DELETE_SAMPLE }
  },
  computed: {
    ...mapGetters({ isLoading: GET_IS_LOADING_SAMPLES }),
    dialogDataId() {
      return this.getDialog(this.name)?.data?.id
    },
  },
  methods: {
    ...mapActions({ deleteSample: DELETE_SAMPLE }),
    handleRemoveQuota(isRemove = false) {
      if (isRemove) {
        this.$message({
          type: 'info',
          message: 'Отправлен запрос на удаление шаблона',
          customClass: 'driver-message',
        })
        this.deleteSample({ template_quota_id: this.dialogDataId })
      } else {
        this.$message({
          type: 'info',
          message: 'Удаление шаблона не подтвержденно',
          customClass: 'driver-message',
        })
      }
      this.setDialog({ name: this.name }, false)
    },
  },
}
</script>

<style lang="sass">
.home-delete-quota
  .el-dialog__header
    background: #F56C6C!important
  .alert-title
    font-weight: bold
    font-size: 1.8em
    margin: 0 0 24px 0
    @media only screen and (max-width: 768px)
      font-size: 1.8em
      line-height: 130% !important
    @media only screen and (max-width: 456px)
      font-size: 1.8em
      line-height: 130% !important
      margin: 0px 0px 8px 0px!important
    .alert-text
      font-size: 18px
      margin-bottom: 18px
    @media only screen and (max-width: 768px)
      font-size: 16px!important
    @media only screen and (max-width: 456px)
      font-size: 14px!important

.dialog-delete-quota
  &__message
    color: #303133
    margin-top: 16px
    margin-bottom: 24px
    font-size: 16px
  .el-button + .el-button
    margin-left: 35px
</style>
